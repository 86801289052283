import { Figure } from "../interfaces/figure";
import { Inputs } from "../interfaces/inputs";
import FigureSection from "./FigureSection";
import PresentationSection from "./PresentationSection";
import ResultSection from "./ResultSection";
import { JSX } from 'react';

const ResultTable = ({inputs, figures, isValidated, queue}: {inputs: Inputs, figures: Figure[], isValidated: boolean, queue:string[][]}) => {
    const weightMaterial: JSX.Element[] = [];
    const priceAll: JSX.Element[] = [];
    const pricePerItem: JSX.Element[] = [];
    const surface: JSX.Element[] = [];
    const stripsPerPlate: JSX.Element[] = [];
    const startedPlates: JSX.Element[] = [];
    const usedPlates: JSX.Element[] = [];
    const stripsInLastPlate: JSX.Element[] = [];
    const cutLineLength: JSX.Element[] = [];
    const cutCost: JSX.Element[] = [];
    const stripsNumber: number[] = [];
    // useMemo(() => , []);
    let lowestPrice;
    let optimalNumber = 0;

    // useEffect(() => {
    //     console.log(queue);
    // }, [queue]);

    const getOptimalNumber = (): number => {
        let number = stripsNumber.sort((a:number, b: number) => {return a-b;}).find(e => e >= inputs.amount);
        let potentialNumbers = [...stripsNumber];
        if(number === undefined) {
            for(let i = 0; i < potentialNumbers.length; i++) {
                while(potentialNumbers[i] < inputs.amount && stripsNumber[i] > 0) {
                    potentialNumbers[i] += stripsNumber[i];
                }
            }
            number = potentialNumbers.sort((a:number, b: number) => {return a-b;}).find(e => e >= inputs.amount) ?? 0;
        }
        return number;
    }

    const isFigureValid = (figure: Figure): boolean => {
        // console.log('===============================');
        // console.log(`Figure x: ${figure.x}, Figure y: ${figure.y}, Inputs x: ${inputs.x}, Inputs y: ${inputs.y}`);
        // console.log(Math.max(inputs.x, inputs.y) <= Math.max(figure.x, figure.y));
        // console.log(Math.min(inputs.y, inputs.x) <= Math.min(figure.x, figure.y));
        // return Math.max(inputs.x, inputs.y) <= Math.max(figure.x, figure.y) && Math.min(inputs.y, inputs.x) <= Math.min(figure.x, figure.y);
        
        // Disabled for testing
        // return inputs.x <= figure.x && inputs.y <= figure.y;
        return true;
    }

    if(isValidated) {
        for(let i = 0; i < figures.length; i++) {
            const element = ResultSection({inputs, figure: figures[i], queue: queue[i]});
            if(isFigureValid(figures[i])) {
                if((lowestPrice == null || element.priceAll < lowestPrice) && element.weightMaterial > 0) {
                    lowestPrice = element.priceAll;
                }
            }
        }
        for(let i = 0; i < figures.length; i++) {
            const element = ResultSection({inputs, figure: figures[i], queue: queue[i]});
            const isValid = isFigureValid(figures[i]);
            stripsNumber.push(isValid ? element.stripsPerPlate : -1);
            weightMaterial.push(<td key={i}>{isValid ? element.weightMaterial : ''}</td>);
            priceAll.push(<td key={i} className={isValid ? element.priceAll === lowestPrice ? 'lowestPrice' : '' : ''}>{isValid ? element.priceAll : ''}</td>);
            pricePerItem.push(<td key={i}>{isValid ? element.pricePerItem : ''}</td>);
            surface.push(<td key={i}>{isValid ? element.surface : ''}</td>);
            stripsPerPlate.push(<td key={i}>{isValid ? element.stripsPerPlate : ''}</td>);
            startedPlates.push(<td key={i}>{isValid ? element.startedPlates : ''}</td>);
            usedPlates.push(<td key={i}>{isValid ? element.usedPlates : ''}</td>);
            stripsInLastPlate.push(<td key={i}>{isValid ? element.stripsInLastPlate : ''}</td>);
            cutLineLength.push(<td key={i}>{isValid ? element.cutLineLength : ''}</td>);
            cutCost.push(<td key={i}>{isValid ? element.cutCost : ''}</td>);
        }
        if(inputs.amount > 0)
            optimalNumber = getOptimalNumber();
        
    } else {
        for(let i = 0; i < figures.length; i++) {
            weightMaterial.push(<td key={i}></td>);
            priceAll.push(<td key={i}></td>);
            pricePerItem.push(<td key={i}></td>);
            surface.push(<td key={i}></td>);
            stripsPerPlate.push(<td key={i}></td>);
            startedPlates.push(<td key={i}></td>);
            usedPlates.push(<td key={i}></td>);
            stripsInLastPlate.push(<td key={i}></td>);
            cutLineLength.push(<td key={i}></td>);
            cutCost.push(<td key={i}></td>);
        }
    }
    lowestPrice ??= 0;

    return (
        <>
            <PresentationSection
                priceAll={lowestPrice}
                pricePerItem={inputs.amount > 0 ? Math.round(lowestPrice * 100 / inputs.amount) / 100 : 0}
                optimalNumber={optimalNumber}
            />
            <table className="border-2 rounded-lg overflow-x-auto">
                <thead>
                    <FigureSection figures={figures} queue={queue} />
                </thead>
                <tbody>
                    <tr>
                        <td>Waga materiału:</td>
                        {weightMaterial}
                    </tr>
                    <tr>
                        <td>Cena całość:</td>
                        {priceAll}
                    </tr>
                    <tr>
                        <td>Cena/szt:</td>
                        {pricePerItem}
                    </tr>
                    <tr>
                        <td>Powierzchnia:</td>
                        {surface}
                    </tr>
                    <tr>
                        <td>Ilość pasków w płycie:</td>
                        {stripsPerPlate}
                    </tr>
                    <tr>
                        <td>Ilość zaczętych płyt:</td>
                        {startedPlates}
                    </tr>
                    <tr>
                        <td>Ilość zużytych całych płyt:</td>
                        {usedPlates}
                    </tr>
                    <tr>
                        <td>Ilość pasków w ost płycie:</td>
                        {stripsInLastPlate}
                    </tr>
                    <tr>
                        <td>Długość linii cięcia:</td>
                        {cutLineLength}
                    </tr>
                    <tr>
                        <td>Koszt cięcia:</td>
                        {cutCost}
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default ResultTable;