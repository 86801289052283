import { useCallback, useEffect, useState } from "react";
import { Figure } from "../interfaces/figure";
import { Result } from "../interfaces/result";
import {Inputs} from "../interfaces/inputs";

const ResultSection = ({inputs, figure, queue}: {inputs: Inputs, figure: Figure, queue: string[]}): Result => {
    const CUT_THICKNESS = 5;
    const [result, setResult] = useState<Result>({
        cutCost: 0,
        cutLineLength: 0,
        priceAll: 0,
        pricePerItem: 0,
        startedPlates: 0,
        stripsInLastPlate: 0,
        stripsPerPlate: 0,
        surface: 0,
        usedPlates: 0,
        weightMaterial: 0
    });

    const calculateCutWidth = useCallback((figure_dimension: number, cut_dimension: number): number => {
        return figure_dimension === cut_dimension ? cut_dimension : cut_dimension + CUT_THICKNESS;
    }, []);

    const roundToDecimal = useCallback((n: number): number => {
        return Math.round(n * 100) / 100;
    }, []);

    const getNumberInStripes = useCallback((figure: Figure, x: number, y: number): number => {
        if(figure.isVertical) {
            const result = Math.trunc(figure.x / calculateCutWidth(figure.x, x));
            queue.push(`Ilość sztuk w pasku = X formatki - ${figure.x} / X formatki - ${calculateCutWidth(figure.x, y)}, po zaokrągleniu całkowitym = ${roundToDecimal(result)}`);
            return result;
        } else {
            const result = Math.trunc(figure.x / calculateCutWidth(figure.y, y));
            queue.push(`Ilość sztuk w pasku = X formatki - ${figure.x} / Y formatki - ${calculateCutWidth(figure.y, y)}, po zaokrągleniu całkowitym = ${roundToDecimal(result)}`);
            return result;
        }
    }, [calculateCutWidth, roundToDecimal, queue]);

    const getStripsNumber = useCallback((amount: number, numberInStripes: number): number => {
        if(numberInStripes === 0) {
            queue.push('Ilość sztuk w pasku = 0, więc ilość pasków = 0');
            return 0;
        } else {
            const result = Math.ceil(amount / numberInStripes);
            queue.push(`Ilość pasków = Wprowadzona ilość - ${amount} / ilość sztuk w pasku - ${numberInStripes}, po zaokrągleniu w górę = ${roundToDecimal(result)}`);
            return result;
        }
    }, [roundToDecimal, queue]);

    const getSurface = useCallback((figure: Figure, x: number, y: number, stripsInLastPlate: number, usedPlates: number): number => {
        if(figure.isVertical) {
            const result = (stripsInLastPlate * calculateCutWidth(figure.y, y) * figure.x) / 1_000_000 + (figure.x * figure.y * usedPlates) / 1_000_000;
            queue.push(`Powierzchnia = (Paski w ostatniej płycie - ${stripsInLastPlate} * Y formatki - ${calculateCutWidth(figure.y, y)} * 
            X formatki - ${figure.x}) / 1_000_000 + (X formatki - ${figure.x} * Y formatki - ${figure.y} * Ilość zużytych płyt - ${usedPlates}) / 1_000_000 = ${roundToDecimal(result)}`);
            return result;
        } else {
            const result = (stripsInLastPlate * calculateCutWidth(figure.x, x) * figure.x) / 1_000_000 + (figure.x * figure.y * usedPlates) / 1_000_000;
            queue.push(`Powierzchnia = (Paski w ostatniej płycie - ${stripsInLastPlate} * X formatki - ${calculateCutWidth(figure.x, y)} * 
            X formatki - ${figure.x}) / 1_000_000 + (X formatki - ${figure.x} * Y formatki - ${figure.y} * Ilość zużytych płyt - ${usedPlates}) / 1_000_000 = ${roundToDecimal(result)}`);
            return result;
        }
    }, [calculateCutWidth, roundToDecimal, queue]);

    const getStripsPerPlate = useCallback((figure: Figure, x: number, y: number): number => {
        if(figure.isVertical) {
            const result = Math.floor(figure.y / calculateCutWidth(figure.y, y));
            queue.push(`Ilość pasków w płycie = Y formatki - ${figure.y} / Y formatki - ${calculateCutWidth(figure.y, y)}, po zaokrągleniu w dół = ${roundToDecimal(result)}`);
            return result;
        } else {
            const result = Math.floor(figure.y / calculateCutWidth(figure.x, x));
            queue.push(`Ilość pasków w płycie = Y formatki - ${figure.y} / X formatki - ${calculateCutWidth(figure.x, y)}, po zaokrągleniu w dół = ${roundToDecimal(result)}`);
            return result;
        }
    }, [calculateCutWidth, roundToDecimal, queue]);

    const getStartedPlates = useCallback((stripsNumber: number, stripsPerPlate: number): number => {
        if(stripsPerPlate === 0) {
            queue.push(`Ilość pasków w płycie = 0, więc liczba zaczętych płyt = 0`);
            return 0;
        } else {
            const result = Math.ceil(stripsNumber / stripsPerPlate);
            queue.push(`Ilość zaczętych płyt = ilość pasków - ${result} / ilość pasków w płycie - ${stripsPerPlate}, po zaokrągleniu w górę = ${roundToDecimal(result)}`);
            return result;
        }
    }, [roundToDecimal, queue]);

    const getUsedPlates = useCallback((startedPlates: number): number => {
        if(startedPlates === 0) {
            queue.push(`Ilość zaczętych płyt = 0, więc liczba zużytych płyt = 0`);
            return 0;
        } else {
            queue.push(`Ilość zużytych płyt = Ilość zaczętych płyt - ${startedPlates} - 1 = ${startedPlates - 1}`);
            return startedPlates - 1;
        }
    }, [queue]);

    const getStripsInLastPlate = useCallback((stripsNumber: number, usedPlates: number, stripsPerPlate: number): number => {
        const result = stripsNumber - usedPlates * stripsPerPlate;
        queue.push(`Paski w ostatniej płycie = Paski w płycie - ${stripsNumber} - Zużyte formatki - ${usedPlates} * Paski w płycie - ${stripsPerPlate} = ${roundToDecimal(result)}`);
        return result;
    }, [roundToDecimal, queue]);

    const getCutLineLength = useCallback((stripsNumber: number, figure: Figure, inputs: Inputs): number => {
        if(figure.x === inputs.x && figure.y === inputs.y) {
            queue.push(`Formatka ma takie same wymiary jak płyta, więc długość linii cięcia = 0`);
            return 0;
        } else if(figure.isVertical) {
            const result = (stripsNumber * figure.x) / 1000 + inputs.y * inputs.amount / 1000;
            queue.push(`Długość linii cięcia = (Ilość pasków - ${stripsNumber} * X formatki - ${figure.x}) / 1000 + (wprowadzony Y - ${inputs.y} * wprowadzona ilość - ${inputs.amount}) / 1000 = ${roundToDecimal(result)}`);
            return result;
        } else {
            const result = (stripsNumber * figure.x) / 1000 + inputs.x * inputs.amount / 1000;
            queue.push(`Długość linii cięcia = (Ilość pasków - ${stripsNumber} * X formatki - ${figure.x}) / 1000 + (wprowadzony X - ${inputs.x} * wprowadzona ilość - ${inputs.amount}) / 1000 = ${roundToDecimal(result)}`);
            return result;
        }
    }, [roundToDecimal, queue]);

    const getCutCost = useCallback((cutLineLength: number, cutPrice: number): number => {
        const result = cutLineLength * cutPrice;
        queue.push(`Koszt cięcia = Długość linii cięcia - ${cutLineLength} * wprowadzona cena cięcia ${cutPrice} = ${roundToDecimal(result)}.`);
        return result;
    }, [roundToDecimal, queue]);

    const getWeightMaterial = useCallback((inputs: Inputs, surface: number): number => {
        if(surface === 0) {
            queue.push(`Powierzchnia = 0, więc waga = 0.`);
            return 0;
        } else {
            const materialType = 'weight' + inputs.material.name;
            const result = inputs.plateWeight[materialType as keyof typeof inputs.plateWeight] / 2 * surface;
            queue.push(`Waga = waga materiału - ${inputs.plateWeight[materialType as keyof typeof inputs.plateWeight]} / 2 * powierzchnia - ${roundToDecimal(surface)} = ${roundToDecimal(result)}.`);
            return result;
        }
    }, [roundToDecimal, queue]);

    const getPriceAll = useCallback((price: number, weightMaterial: number, cutCost: number): number => {
        const result = weightMaterial * inputs.price + cutCost;
        queue.push(`Cena za całość = waga - ${roundToDecimal(weightMaterial)} * wprowadzona cena - ${price} + koszt cięcia ${roundToDecimal(cutCost)} = ${roundToDecimal(result)}`);
        return result;
    }, [roundToDecimal, queue, inputs.price]);

    const getPricePerItem = useCallback((number: number, priceAll: number): number => {
        if(number === 0) {
            queue.push(`Ilość sztuk = 0, więc cena za sztukę = 0.`);
            return 0;
        } else {
            const result = priceAll / number;
            queue.push(`Cena za sztukę = cena całość - ${roundToDecimal(priceAll)} / ilość sztuk - ${number} = ${roundToDecimal(result)}`);
            return result;
        }
    }, [roundToDecimal, queue]);

    useEffect(() => {
        queue.length = 0;
        const numberInStripes = getNumberInStripes(figure, inputs.x, inputs.y);
        const stripsNumber = getStripsNumber(inputs.amount, numberInStripes);
        const stripsPerPlate = getStripsPerPlate(figure, inputs.x, inputs.y);
        const startedPlates = getStartedPlates(stripsNumber, stripsPerPlate);
        const usedPlates = getUsedPlates(startedPlates);
        const stripsInLastPlate = getStripsInLastPlate(stripsNumber, usedPlates, stripsPerPlate);
        const surface = getSurface(figure, inputs.x, inputs.y, stripsInLastPlate, usedPlates);
        const weightMaterial = getWeightMaterial(inputs, surface);
        const cutLineLength = getCutLineLength(stripsNumber, figure, inputs);
        const cutCost = getCutCost(cutLineLength, inputs.cutPrice);
        const priceAll = getPriceAll(inputs.price, weightMaterial, cutCost);
        const pricePerItem = getPricePerItem(inputs.amount, priceAll);

        setResult({
            weightMaterial: roundToDecimal(weightMaterial),
            priceAll: roundToDecimal(priceAll),
            pricePerItem: roundToDecimal(pricePerItem),
            surface: roundToDecimal(surface),
            stripsPerPlate: roundToDecimal(stripsPerPlate),
            usedPlates: roundToDecimal(usedPlates),
            startedPlates: roundToDecimal(startedPlates),
            stripsInLastPlate: roundToDecimal(stripsInLastPlate),
            cutLineLength: roundToDecimal(cutLineLength),
            cutCost: roundToDecimal(cutCost),
        });
    }, [inputs, figure, getCutCost, getCutLineLength, getNumberInStripes, getPriceAll, getPricePerItem
        ,getStartedPlates, getStripsInLastPlate, getStripsNumber, getStripsPerPlate, getSurface, getUsedPlates, getWeightMaterial,
        roundToDecimal
    ]);

    return result;
}

export default ResultSection;