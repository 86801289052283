import { useMemo } from "react";
import { Material } from "../interfaces/material";
import { PlateWeight } from "../interfaces/plateWeight";

const SettingsDialog = ({plateWeight, materials, setPlateWeight} : {plateWeight: PlateWeight[], materials: Material[],
     setPlateWeight: Function}) => {
    const getRow = useMemo(() => (i: number) => {
        const result: JSX.Element[] = [<td>{plateWeight[i].thickness}</td>];
        materials.forEach(e => {
            const materialType = 'weight' + e.name;
            result.push(<td><input className="" style={{width: '100%'}} onChange={e => {
                const val = parseFloat(e.currentTarget.value);
                if(val >= 0) {
                    plateWeight[i][materialType as keyof typeof plateWeight[0]] = val;
                    setPlateWeight([...plateWeight]);
                }
            }} type="number" value={plateWeight[i][materialType as keyof typeof plateWeight[0]]} /> </td>);
        });
        return <tr>{result}</tr>;
    }, [materials, plateWeight, setPlateWeight]);

    const tabs: JSX.Element[] = useMemo(() => {
        const tabs: JSX.Element[] = [<th></th>];
        materials.forEach((e) => {tabs.push(<th>{e.name}</th>)});
        return tabs;
    }, [materials]);

    const results: JSX.Element[] = useMemo(() => {
        const result: JSX.Element[] = [];
        for(let i = 0; i < plateWeight.length; i++) {
            result.push(
                getRow(i)
            );
        }
        return result;
    }, [plateWeight, getRow]);

    return (
        <>
        <table className="rounded-lg settingsTable">
            <thead>
                <tr>
                    {tabs}
                </tr>
            </thead>
            <tbody>
                {results}
            </tbody>
        </table>
        </>
    );
}

export default SettingsDialog;