
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Error } from '../interfaces/error';
import { useNavigate, useSearchParams } from 'react-router-dom';

const HeaderSection = ({error}: {error: Error}) => {
    const [errors, setErrors] = useState<JSX.Element[]>([]);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const isAdmin = searchParams.get('admin') === '1';
    useEffect(() => {
        const set = new Set<string>();
        for(const key in error) {
            set.add((error as { [key: string]: any })[key]);
        }
        const result: JSX.Element[] = [];
        set.forEach(e => {
            result.push(<h3 className="text-red-600">{e}</h3>)
        });
        setErrors(result);
    }, [error]);


    return (
        <section className='flex justify-between settingsTable'>
            <div>
                <img src="https://zatorskib2b.komplus.pl/images/logo.png" alt='Zatorski logo'></img>
            </div>
            <header className="text-center py-4">
                <h3 className="font-bold text-4xl tracking-wider">Kalkulator formatek</h3>
                {errors}
            </header>
            <div className=' text-xl'>
                <button style={{display: (isAdmin ? 'block' : 'none')}} onClick={() => navigate("/settings")}>
                    <FontAwesomeIcon icon={faGear} />
                </button>
            </div>
        </section>
    );
}

export default HeaderSection;